/* eslint-disable import/no-cycle */
import {createSlice} from '@reduxjs/toolkit';
import {
    DATE_FILTER_OPTIONS,
	GRIDVIEWS,
	MODULE_PATH,
	TaskBoardStatus,
	TASKVIEWS,
	TIME_DISPLAY_OPTIONS
} from 'app/main/constants/taskBoardConstants';
import _ from 'lodash';
import moment from 'moment';
import {changeTaskGridDateFormat, processDeletedTask, processTaskComments} from './api/processTaskItems';
import processTaskLocation from './api/processTaskLocation';
import {getAllReoccurring, getReoccurring, getReoccurringById, updateReoccurringStatus} from './api/reoccurringApi';
import {
	deleteAttachment,
	deleteMultipleTasks,
	getAllLocationTasks,
	getAllTasks,
	getAttachments,
	getTask,
	getTaskComments,
	getTaskDailyCount,
	getTaskMasterData,
	getTasks,
	getTaskSummaryData,
	updateAttachmentComment,
	updateStatusTasks
} from './api/taskApi';
import {getTaskClient} from './api/taskCustomerApi';
import {getMasterLocations, getTaskLocations} from './api/taskLocationApi';
import {checkIsAdminUser, checkIsTaskLead} from '../utils/taskUtils';
import { getClientComments } from './api/clientCommentsApi';

export const initialState = {
    selectedTaskData: {},
    selectedTaskAttachments: [],
    chosenAttachmentsFiles: [],
    selectedTaskComments: [],
    selectedTaskForms: [],
    taskBoardGridRows: [],
    reoccurringGridRows: [],
    locationTasks: [],
    // taskGridMetaData: {},
    // reoccurringGridMetaData: {},
    taskLocations: [],
    masterLocations: [],
    masterAndTaskLocations: [],
    selectedMultipleTasks: [],
    taskUsers: [],
    taskTypes: [],
    taskItemSuggestions: [],
    incompleteTaskCount: undefined,
    taskCustomers: {
        clientId: '',
        notes: []
    },
    selectedClientId: '',
    selectedClientComments: [],
    visibleExportModal: false,
    isAdminUser: false,
    isTaskLead: false,
    userData: {},
    selectedGridView: GRIDVIEWS.LIST,
    selectedTaskView: TASKVIEWS.GRID,
    gridShowTime: {
        [GRIDVIEWS.LIST]: {
            showTime: false,
            displayOption: TIME_DISPLAY_OPTIONS.TWELVE_HOURS
        },
        [GRIDVIEWS.NESTED]: {
            showTime: false,
            displayOption: TIME_DISPLAY_OPTIONS.TWELVE_HOURS
        },
        [GRIDVIEWS.DAILY_PLANNER]: {
            showTime: true,
            displayOption: TIME_DISPLAY_OPTIONS.TWELVE_HOURS
        }
    },
    attachmentPreviewLoadingId: false,
    taskDueDates: [],
    technicianMultiAssignNames: [],
    isDefaultFilter: false,
    selectedModulePath: MODULE_PATH[GRIDVIEWS.LIST],
    showDailyPlannerOrdering: false,
    selectedDailyPlannerDate: moment().format('MM/DD/YYYY'),
    selectedDailyPlannerAssignTo: {name: null, id: null},
    timerOnTask: {}, // For admin to see each users in progress task in daily planner screen
    techInProgTask: {},
    multiEditModelVisible: false,
    drawerState: {
        isOpen: false,
        actionType: 'create',
        screen: GRIDVIEWS.LIST
    },
    // Advanced Search
    gridSearchTerm:'',
    isAdvancedSearchActive: false,
    resetGrid: false,
    reloadGrid: false,
    taskViewFilters: {
        locName: [],
        clientName: [],
        status: [],
        assignedUsers: [],
        isRecurringTask: undefined,
        isActive: undefined,
        taskType: [],
        recurringType: [],
        frequencyTag: [],
        dueDate: {
            isDefault: false,
            filter: DATE_FILTER_OPTIONS.IN_RANGE ,
            startDate: moment.utc(new Date()).subtract( 6, 'months').format('YYYY-MM-DD'),
            endDate: new Date(),
        },
    },
    isMapDataLoading: false,
    taskDailyCount: {}, // adding in redux to avoid no data for first month when assign user switch
    dailyPlannerGridReload: false // toggle for triggering useEffect
};

const taskBoardGridSlice = createSlice({
    name: 'taskBoardGrid',
    initialState,
    reducers: {
        setSelectedMultipleTasks: (state, action) => {
            state.selectedMultipleTasks = action.payload;
        },
        setSelectedTaskData: (state, action) => {
            state.selectedTaskData = action.payload;
        },
        resetSelectedTasks: (state) => {
            state.selectedTaskData = {};
            state.selectedMultipleTasks = [];
        },
        setTaskUsers: (state, action) => {
            // const taskUsers = action.payload?.filter(tech => tech.roles.includes('schedu:admin'));
            // List all technicians in Assigned To dropdown for now
            const taskUsers = _.cloneDeep(action.payload);
            taskUsers.unshift({
                technicianFullName: 'Third Party'
            });
            state.taskUsers = taskUsers;
        },
        setVisibleExportModal: (state, action) => {
            state.visibleExportModal = action.payload;
        },
        setUserData: (state, action) => {
            state.userData = action.payload;
            state.isAdminUser = checkIsAdminUser(action.payload);
            state.isTaskLead = checkIsTaskLead(action.payload);
        },
        setSelectedGridView: (state, action) => {
            state.selectedGridView = action.payload;
            state.selectedModulePath =
                action.payload === GRIDVIEWS.DAILY_PLANNER
                    ? MODULE_PATH[GRIDVIEWS.DAILY_PLANNER]
                    : action.payload === GRIDVIEWS.NESTED
                    ? MODULE_PATH[GRIDVIEWS.NESTED]
                    : MODULE_PATH[GRIDVIEWS.LIST];
        },
        setSelectedTaskView: (state, action) => {
            state.selectedTaskView = action.payload;
        },
        toggleGridShowTime: (state, action) => {
            state.gridShowTime[action.payload] = {
                showTime: state.gridShowTime[action.payload] ? !state.gridShowTime[action.payload].showTime : true,
                displayOption: TIME_DISPLAY_OPTIONS.TWELVE_HOURS
            };
        },
        changeTimeDisplay: (state, action) => {
            state.gridShowTime[action.payload.gridView] = {
                showTime: true,
                displayOption: action.payload.displayOption
            };
        },
        setGridShowTime: (state, action) => {
            state.gridShowTime = action.payload;
        },
        appendAttachments: (state, action) => {
            state.selectedTaskAttachments = [...action.payload, ...state.selectedTaskAttachments];
        },
        removeNotUploadedAttachments: (state) => {
            state.selectedTaskAttachments = _.filter(state.selectedTaskAttachments, item => item.id);
        },
        removeSelectedAttachmentFromGrid: (state, action) => {
            state.selectedTaskAttachments = _.filter(state.selectedTaskAttachments, attachment => attachment.id !== action.payload?.id);
        },
        resetAttachments: (state) => {
            state.selectedTaskAttachments = [];
        },
        resetClientComments: (state) => {
            state.selectedClientComments = [];
        },
        setSelectedClientId: (state, action) => {
            state.selectedClientId = action.payload;
        },
        resetSelectedClientId: (state) => {
            state.selectedClientId = '';
        },
        selectedAttachmentFiles: (state, action) => {
            state.chosenAttachmentsFiles = [...action.payload];
        },
        clearSelectedAttachmentFiles: (state) => {
            state.chosenAttachmentsFiles = [];
        },
        setAttachmentPreviewLoadingId: (state, action) => {
            state.attachmentPreviewLoadingId = action.payload;
        },
        setDefaultFilterValue: (state, action) => {
            state.isDefaultFilter = action.payload;
        },
        setCurrentModulePath: (state, action) => {
            state.currentModulePath = action.payload;
        },
        taskDeletePostFn: (state, action) => {
            const {isMasterScreen} = action.payload;
            const {ids} = action.payload;
            state.selectedMultipleTasks = [];
            state.selectedTaskData = {};
            if (!isMasterScreen) {
                state.taskBoardGridRows = processDeletedTask(state.taskBoardGridRows, ids);
            }
        },
        setShowDailyPlannerOrdering: (state, action) => {
            state.showDailyPlannerOrdering = action.payload;
        },
        setSelectedDailyPlannerDate: (state, action) => {
            state.selectedDailyPlannerDate = action.payload;
        },
        setSelectedDailyPlannerAssignTo: (state, action) => {
            state.selectedDailyPlannerAssignTo = action.payload;
        },
        setTimerOnTask: (state, action) => {
            state.timerOnTask = action.payload;
        },
        setTechInProgTask: (state, action) => {
            state.techInProgTask = action.payload;
        },
        setMultiEditModel: (state, action) => {
            state.multiEditModelVisible = action.payload;
        },
        setTaskDrawerState: (state, action) => {
            state.drawerState = action.payload;
        },
        setIsAdvancedSearchActive: (state, { payload }) => {
            state.isAdvancedSearchActive = payload;
        },
        resetAdvancedSearch: (state) => {
            state.isAdvancedSearchActive = false;
            state.taskViewFilters = {
                ...initialState.taskViewFilters,
                dueDate: {
                    filter: DATE_FILTER_OPTIONS.IN_RANGE ,
                    startDate: moment.utc(new Date()).subtract( 6, 'months').format('YYYY-MM-DD'),
                    endDate: new Date(),
                },
            };
            state.gridSearchTerm = initialState.gridSearchTerm;
        },
        toggleResetGrid: (state) => {
            state.resetGrid = !state.resetGrid;
            // state.taskViewFilters = initialState.taskViewFilters;
        },
        setAdvanceSearchFilters: (state, { payload }) => {
            state.taskViewFilters = payload;
        },
        setGridSearchTerm: (state, action) => {
			state.gridSearchTerm = action.payload;
		},
        setReloadGrid: (state) => {
            state.reloadGrid = true;
            state.dailyPlannerGridReload = !state.dailyPlannerGridReload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllTasks.fulfilled, (state, action) => {
            state.taskBoardGridRows = action.payload?.tasks || [];
            // state.taskGridMetaData = action.payload?.metaData || {};
            // state.taskItemSuggestions = action.payload?.taskItems;
            state.taskDueDates = action.payload?.taskDueDates;
            state.technicianMultiAssignNames = action.payload?.technicianMultiAssignNames;
            state.techInProgTask = action.payload?.techInProgTask;
        })
        .addCase(getAllReoccurring.fulfilled, (state, action) => {
            state.reoccurringGridRows = action.payload;
        })
        .addCase(getAllLocationTasks.pending, (state, action) => {
            state.isMapDataLoading = true;
          })
        .addCase(getAllLocationTasks.rejected, (state, action) => {
            state.isMapDataLoading = false;
        })
        .addCase(getAllLocationTasks.fulfilled, (state, action) => {
            state.locationTasks = action.payload;
            state.isMapDataLoading = false;
        })
        .addCase(getTasks.fulfilled, (state) => {
            state.reloadGrid = false;
            // state.reoccurringGridMetaData = action.payload?.metaData || {};
        })
        .addCase(getReoccurring.fulfilled, (state) => {
            state.reloadGrid = false;
        })
        .addCase(getTaskLocations.fulfilled, (state, action) => {
            state.taskLocations = action.payload;
            state.masterAndTaskLocations = processTaskLocation(
                state.masterLocations,
                state.taskLocations,
                state.userData
            );
        })
        .addCase(getMasterLocations.fulfilled, (state, action) => {
            state.masterLocations = action.payload;
            state.masterAndTaskLocations = processTaskLocation(
                state.masterLocations,
                state.taskLocations,
                state.userData
            );
        })
        .addCase(getTaskMasterData.fulfilled, (state, action) => {
            state.taskTypes = action.payload;
        })
        .addCase(getTaskClient.fulfilled, (state, action) => {
            state.taskCustomers = action.payload;
        })
        .addCase(getClientComments.fulfilled, (state, action) => {
            state.selectedClientComments = action.payload;
        })
        .addCase(updateStatusTasks.fulfilled, (state, action) => {
            const updatedTasks = {};
            _.forEach(action.payload, obj => {
                updatedTasks[obj.id] = obj;
            });
            state.taskBoardGridRows = _.map(state.taskBoardGridRows, task => {
                const matchedTask = updatedTasks[task.id];
                const completedDate =
                    matchedTask?.status === TaskBoardStatus.COMPLETED
                        ? matchedTask?.completedDate
                            ? moment(matchedTask?.completedDate).toDate()
                            : new Date()
                        : task.completedDate;
                const actualStartDate =
                    matchedTask?.status === TaskBoardStatus.INCOMPLETE || matchedTask?.isResetTimer
                        ? null
                        : matchedTask?.actualStartDate
                        ? moment(matchedTask?.actualStartDate).toDate()
                        : task.actualStartDate;
                return {
                    ...task,
                    status: matchedTask?.status || task.status,
                    completedDateStr: changeTaskGridDateFormat(completedDate),
                    actualStartDate,
                    completedDate
                };
            });
            const techTimerTaskDone = [TaskBoardStatus.COMPLETED, TaskBoardStatus.INCOMPLETE].includes(
                updatedTasks[state.techInProgTask?.id]?.status
            );
            state.selectedMultipleTasks = [];
            state.selectedTaskData = {};
            state.techInProgTask = techTimerTaskDone ? {} : state.techInProgTask;
        })
        .addCase(updateReoccurringStatus.fulfilled, (state, action) => {
            const {id: taskId, isActive: isReoccurringActive} = action.payload || {};
            if (!_.isEmpty(state.selectedTaskData) && state.selectedTaskData.id === taskId) {
                state.selectedTaskData.isReoccurringActive = isReoccurringActive;
            }
            if (!_.isEmpty(state.selectedMultipleTasks)) {
                state.selectedMultipleTasks = _.map(state.selectedMultipleTasks, item => {
                    if (item.id === taskId) {
                        return {
                            ...item,
                            isReoccurringActive
                        };
                    }
                    return item;
                });
            }
        })
        .addCase(deleteMultipleTasks.fulfilled, (state, action) => {
            // TODO: update nested view also
            state.taskBoardGridRows = processDeletedTask(state.taskBoardGridRows, action.payload);
            state.selectedMultipleTasks = [];
            state.selectedTaskData = {};
        })
        .addCase(getAttachments.fulfilled, (state, action) => {
            state.selectedTaskAttachments = action.payload;
        })
        .addCase(updateAttachmentComment.fulfilled, (state, action) => {
            state.selectedTaskAttachments = _.map(state.selectedTaskAttachments, attachment => {
                const comments = action.payload?.id === attachment.id ? action.payload?.comments : attachment.comments;
                return {
                    ...attachment,
                    comments
                };
            });
        })
        .addCase(deleteAttachment.fulfilled, (state, action) => {
            state.selectedTaskAttachments = _.filter(
                state.selectedTaskAttachments,
                attachment => attachment.id !== action.payload?.id
            );
        })
        .addCase(getTaskComments.fulfilled, (state, action) => {
            state.selectedTaskComments = processTaskComments(action.payload);
        })
        .addCase(getTask.fulfilled, (state, action) => {
            state.selectedTaskComments = processTaskComments(action.payload?.comments);
            state.selectedTaskAttachments = action.payload?.attachments || [];
            state.selectedTaskForms = action.payload?.taskForms || [];
        })
        .addCase(getReoccurringById.fulfilled, (state, action) => {
            state.selectedTaskAttachments = action.payload?.attachments || [];
        })
        .addCase(getTaskSummaryData.fulfilled, (state, action) => {
            state.taskItemSuggestions = action.payload?.taskItems || [];
            state.incompleteTaskCount = action.payload?.incompleteTaskCount || 0;
        })
        .addCase(getTaskDailyCount.fulfilled, (state, action) => {
            state.taskDailyCount = action.payload || {};
        });
    }
});

export const {
    setSelectedMultipleTasks,
    setTaskUsers,
    setSelectedTaskData,
    resetSelectedTasks,
    setVisibleExportModal,
    setUserData,
    setSelectedGridView,
    setSelectedTaskView,
    toggleGridShowTime,
    changeTimeDisplay,
    setGridShowTime,
    appendAttachments,
    setAttachmentPreviewLoadingId,
    removeNotUploadedAttachments,
    setDefaultFilterValue,
    setCurrentModulePath,
    taskDeletePostFn,
    setShowDailyPlannerOrdering,
    setSelectedDailyPlannerDate,
    setSelectedDailyPlannerAssignTo,
    setTimerOnTask,
    setTechInProgTask,
    setMultiEditModel,
    selectedAttachmentFiles,
    clearSelectedAttachmentFiles,
    resetAttachments,
    resetClientComments,
    setSelectedClientId,
    resetSelectedClientId,
    removeSelectedAttachmentFromGrid,
    setTaskDrawerState,
    resetAdvancedSearch,
    setIsAdvancedSearchActive,
    toggleResetGrid,
    setAdvanceSearchFilters,
    setGridSearchTerm,
    setReloadGrid
} = taskBoardGridSlice.actions;

export default taskBoardGridSlice.reducer;
