// task-board
export const TASKS = '/api/v1/tasks';
export const GET_TASKS = '/api/v1/tasks';
export const ADD_TASKS = '/api/v1/tasks';
export const UPDATE_TASK = '/api/v1/tasks';
export const UPDATE_STATUS_TASKS = '/api/v1/tasks/update-status';
export const VALIDATE_TASKS = '/api/v1/tasks/validate';
export const DELETE_MULTIPLE_TASKS = '/api/v1/tasks/delete-tasks';
export const GET_TASK_MASTER_DATA = '/api/v1/tasks/master-data';
export const GET_TASK_DAILY_COUNT = '/api/v1/tasks/daily-count';
export const MULTI_UPDATE_TASK = '/api/v1/tasks/update-tasks';
export const TASKS_PAGINATED ='/api/v2/tasks';
export const LOCATION_TASKS ='/api/v1/location/tasks';
export const TASK_SUMMARY_DATA ='/api/v1/tasks/summary';

// task-board locations
export const GET_TASK_LOCATIONS = '/api/v1/task-location';
export const ADD_TASK_LOCATIONS = '/api/v1/task-location';
export const UPDATE_TASK_LOCATIONS = '/api/v1/task-location';
// task customer
export const TASK_CLIENTS = '/api/v1/task-clients';
export const TASK_SCHEDULE = '/api/v1/task-schedule';
export const CLIENTS_COMMENTS = '/api/v1/clients';

// reoccurring config
export const REOCCURRING_CONFIG = '/api/v1/recurring-config';
export const RECURRING_CONFIG_PAGINATED = '/api/v2/recurring-config';

// mdm
// export const MASTER_LOCATION = '/v1/locations';
export const MASTER_LOCATION = '/locations';
export const ATTACHMENT = '/api/v1/attachment';
export const TASK_WITH_ATTACHMENT = '/api/v1/task-with-attachment';
export const REOCCURRING_CONFIG_WITH_ATTACHMENT = '/api/v1/recur-cfg-with-attachment';

// routes
export const ADD_ROUTE = '/api/v1/routes';
export const GET_ROUTES = '/api/v1/routes';
export const DELETE_ROUTE = '/api/v1/routes';
export const UPDATE_ROUTE = '/api/v1/routes';
export const DELETE_MULTIPLE_ROUTES = '/api/v1/routes/delete-routes';

// logistics
