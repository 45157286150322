import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {showMessage} from 'app/store/fuse/messageSlice';
import {BACKEND_SCHEDULER_URL} from 'app/main/constants/serverConstants';
import {
	ADD_TASKS,
	GET_TASKS,
	GET_TASK_MASTER_DATA,
	UPDATE_TASK,
	UPDATE_STATUS_TASKS,
	DELETE_MULTIPLE_TASKS,
	TASKS,
	ATTACHMENT,
	REOCCURRING_CONFIG,
	VALIDATE_TASKS,
	MULTI_UPDATE_TASK,
	TASK_WITH_ATTACHMENT,
	REOCCURRING_CONFIG_WITH_ATTACHMENT,
	TASKS_PAGINATED,
	LOCATION_TASKS,
	TASK_SUMMARY_DATA,
	GET_TASK_DAILY_COUNT
} from './urls';
import {processAttachments, processDataForMap, processTasks} from './processTaskItems';
// eslint-disable-next-line import/no-cycle
import { setReloadGrid } from '../taskBoardGridSlice';

// const LOCAL_URL = 'http://localhost:3005';

export const getAllTasks = createAsyncThunk('taskBoardApp/taskBoardGrid/getAllTasks', async (params, thunkApi) => {
	try {
		params = params || {};
		const {timeZone} = Intl.DateTimeFormat().resolvedOptions();
		params.timezone = timeZone;
		const tasks = (await axios.get(`${BACKEND_SCHEDULER_URL}${GET_TASKS}`, {params})).data;
		const technicians = thunkApi.getState()?.schedulerCommon?.schedulerCommon?.technicians;
		const user = thunkApi.getState()?.auth?.user;
		const routes = thunkApi.getState()?.taskBoardApp?.routeGrid?.routeGridRows;
		const clients = thunkApi.getState()?.taskBoardApp?.taskBoardGrid?.taskCustomers;
		const newTasks = processTasks(tasks, technicians, user, routes, clients);
		return newTasks;
	} catch (err) {
		console.warn(err);
		return thunkApi.rejectWithValue('Something went wrong. Please try again!');
	}
});


export const getAllLocationTasks = createAsyncThunk('taskBoardApp/taskBoardGrid/getAllLocationTasks', async (params, thunkApi) => {
	try {
		params = params || {};
		// const {timeZone} = Intl.DateTimeFormat().resolvedOptions();
		// params.timezone = timeZone;
		const locationTasks = (await axios.get(`${BACKEND_SCHEDULER_URL}${LOCATION_TASKS}`, {params})).data;
		const technicians = thunkApi.getState()?.schedulerCommon?.schedulerCommon?.technicians;
		const processedTask = await processDataForMap(locationTasks, technicians);
		return processedTask;
	} catch (err) {
		console.warn(err);
		return thunkApi.rejectWithValue('Something went wrong. Please try again!');
	}
});



export const getTasks = createAsyncThunk('taskBoardApp/taskBoardGrid/getTasks', async (params, thunkApi) => {
	try {
		params = params || {};
		// const {timeZone} = Intl.DateTimeFormat().resolvedOptions();
		// params.timezone = timeZone;
		const {data: tasksData, metaData} = (await axios.get(`${BACKEND_SCHEDULER_URL}${TASKS_PAGINATED}`, {params})).data;
		// const {data: tasksData, metaData} = tasks;
		const technicians = thunkApi.getState()?.schedulerCommon?.schedulerCommon?.technicians;
		const user = thunkApi.getState()?.auth?.user;
		const routes = thunkApi.getState()?.taskBoardApp?.routeGrid?.routeGridRows;
		const clients = thunkApi.getState()?.taskBoardApp?.taskBoardGrid?.taskCustomers;
		const newTasks = processTasks(tasksData, technicians, user, routes, clients);
		return {...newTasks, metaData};
	} catch (err) {
		console.warn(err);
		return thunkApi.rejectWithValue('Something went wrong. Please try again!');
	}
});

export const getTask = createAsyncThunk('taskBoardApp/taskBoardGrid/getTask', async (taskId, thunkApi) => {
	try {
		const task = (await axios.get(`${BACKEND_SCHEDULER_URL}${GET_TASKS}/${taskId}`)).data;
		return task;
	} catch (err) {
		console.warn(err);
		return thunkApi.rejectWithValue('Something went wrong. Please try again!');
	}
});

export const getDailyTasks = createAsyncThunk('taskBoardApp/taskBoardGrid/getDailyTasks', async (params, thunkApi) => {
	try {
		const {timeZone} = Intl.DateTimeFormat().resolvedOptions();
		params.timezone = timeZone;
		const tasks = (await axios.get(`${BACKEND_SCHEDULER_URL}${GET_TASKS}`, {params})).data;
		const technicians = thunkApi.getState()?.schedulerCommon?.schedulerCommon?.technicians;
		const user = thunkApi.getState()?.auth?.user;
		const routes = thunkApi.getState()?.taskBoardApp?.routeGrid?.routeGridRows;
		const clients = thunkApi.getState()?.taskBoardApp?.taskBoardGrid?.taskCustomers;
		const newTasks = processTasks(tasks, technicians, user, routes, clients);
		return newTasks;
	} catch (err) {
		console.warn(err);
		return thunkApi.rejectWithValue('Something went wrong. Please try again!');
	}
});

export const createTasks = createAsyncThunk('taskBoardApp/taskBoardGrid/createTasks', async (params, thunkApi) => {
	try {
		const task = (await axios.post(`${BACKEND_SCHEDULER_URL}${ADD_TASKS}`, params)).data;
		// thunkApi.dispatch(getAllTasks());
		thunkApi.dispatch(setReloadGrid());
		thunkApi.dispatch(showMessage({message: 'Task created Successfully', variant: 'success'}));
		return task;
	} catch (err) {
		thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
		return thunkApi.rejectWithValue('Something went wrong. Please try again!');
	}
});

export const createTasksWithAttachment = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/createTasksWithAttachment',
	async (params, thunkApi) => {
		try {
			const task = await axios.post(
				`${BACKEND_SCHEDULER_URL}${params?.isMasterScreen ? REOCCURRING_CONFIG_WITH_ATTACHMENT : TASK_WITH_ATTACHMENT}`,
				params.formData,
				{
					headers: {
						'Content-type': 'multipart/form-data'
					}
				}
			);
			thunkApi.dispatch(setReloadGrid());
			// if(params?.isMasterScreen) await thunkApi.dispatch(getReoccurring());
			// if(params?.isMasterScreen) thunkApi.dispatch(getAllReoccurring());
			// else thunkApi.dispatch(getAllTasks());
			thunkApi.dispatch(showMessage({message: `${params?.isMasterScreen ? 'Reoccurring config created successfully' : 'Task created Successfully'}`, variant: 'success'}));
			return task;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const updateTask = createAsyncThunk('taskBoardApp/taskBoardGrid/updateTask', async (params, thunkApi) => {
	try {
		const task = (await axios.put(`${BACKEND_SCHEDULER_URL}${UPDATE_TASK}/${params.id}`, params)).data;
		const technicians = thunkApi.getState()?.schedulerCommon?.schedulerCommon?.technicians;
		thunkApi.dispatch(showMessage({message: 'Task updated Successfully', variant: 'success'}));
		const user = thunkApi.getState()?.auth?.user;
		const routes = thunkApi.getState()?.taskBoardApp?.routeGrid?.routeGridRows;
		const clients = thunkApi.getState()?.taskBoardApp?.taskBoardGrid?.taskCustomers;
		const updatedTask = processTasks([task], technicians, user, routes, clients);
		// thunkApi.dispatch(getAllTasks());
		thunkApi.dispatch(setReloadGrid());
		return updatedTask?.tasks[0];
	} catch (err) {
		thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
		return thunkApi.rejectWithValue('Something went wrong. Please try again!');
	}
});

export const deleteTask = createAsyncThunk('taskBoardApp/taskBoardGrid/deleteTasks', async (taskId, thunkApi) => {
	try {
		const response = await axios.delete(`${BACKEND_SCHEDULER_URL}${ADD_TASKS}/${taskId}`);
		let id = null;
		if (response.status === 200) {
			id = taskId;
			thunkApi.dispatch(setReloadGrid());
			thunkApi.dispatch(showMessage({message: 'Task deleted Successfully', variant: 'success'}));
		}
		return id;
	} catch (err) {
		thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
		return thunkApi.rejectWithValue('Something went wrong. Please try again!');
	}
});

export const updateStatusTasks = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/updateStatusTasks',
	async (params, thunkApi) => {
		try {
			const response = await axios.post(`${BACKEND_SCHEDULER_URL}${UPDATE_STATUS_TASKS}`, params);
			// let ids = [];
			if (response.status === 200) {
				// ids = params.ids;
				thunkApi.dispatch(setReloadGrid());
				thunkApi.dispatch(showMessage({message: 'Tasks updated Successfully', variant: 'success'}));
			}
			return params;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const deleteMultipleTasks = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/deleteMultipleTasks',
	async (params, thunkApi) => {
		try {
			const response = await axios.post(`${BACKEND_SCHEDULER_URL}${DELETE_MULTIPLE_TASKS}`, params);
			let ids = [];
			if (response.status === 200) {
				ids = params.ids;
				thunkApi.dispatch(setReloadGrid());
				thunkApi.dispatch(showMessage({message: 'Tasks deleted Successfully', variant: 'success'}));
			}
			return ids;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const getTaskMasterData = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/getTaskMasterData',
	async (params, thunkApi) => {
		try {
			const taskMasterData = (await axios.get(`${BACKEND_SCHEDULER_URL}${GET_TASK_MASTER_DATA}`)).data;
			return taskMasterData;
		} catch (err) {
			console.warn(err);
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const updateTaskOrder = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/updateTaskOrder',
	async (params, thunkApi) => {
		try {
			const taskOrdered = (await axios.post(`${BACKEND_SCHEDULER_URL}${UPDATE_TASK}/ordering`, params)).data;
			thunkApi.dispatch(
				showMessage({
					message: `Task Order Updated Successfully`,
					variant: 'success'
				})
			);
			return taskOrdered;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);
export const getAttachmentSignedURL = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/getAttachmentURL',
	async (params, thunkApi) => {
		try {
			const attachments = (await axios.get(`${BACKEND_SCHEDULER_URL}${ATTACHMENT}/${params.id}`)).data;
			return attachments;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const getAttachments = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/getAttachments',
	async (params, thunkApi) => {
		try {
			const attachments = (
				await axios.get(
					`${BACKEND_SCHEDULER_URL}${params?.isMasterScreen ? REOCCURRING_CONFIG : TASKS}/${
						params.id
					}/attachment`
				)
			).data;
			const newData = processAttachments(attachments);
			return newData;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const uploadAttachments = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/uploadAttachments',
	async (params, thunkApi) => {
		try {
			const attachments = await axios.post(
				`${BACKEND_SCHEDULER_URL}${params?.isMasterScreen ? REOCCURRING_CONFIG : TASKS}/${
					params?.id
				}/attachment`,
				params.formData,
				{
					headers: {
						'Content-type': 'multipart/form-data'
					}
				}
			);
			thunkApi.dispatch(getAttachments({id: params?.id, isMasterScreen: params?.isMasterScreen}));
			thunkApi.dispatch(
				showMessage({
					message: `File uploaded successfully`,
					variant: 'success'
				})
			);
			return attachments;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const deleteAttachment = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/deleteAttachment',
	async (params, thunkApi) => {
		try {
			await axios.delete(`${BACKEND_SCHEDULER_URL}${ATTACHMENT}/${params?.id}`);
			thunkApi.dispatch(
				showMessage({
					message: `File deleted successfully`,
					variant: 'success'
				})
			);
			return params;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const updateAttachmentComment = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/updateAttachmentComment',
	async (params, thunkApi) => {
		try {
			const attachments = (
				await axios.post(`${BACKEND_SCHEDULER_URL}${ATTACHMENT}/${params?.id}/comments`, {
					comments: params.comment
				})
			).data;

			thunkApi.dispatch(
				showMessage({
					message: `Comment updated successfully`,
					variant: 'success'
				})
			);
			return attachments;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const getTaskComments = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/getTaskComments',
	async (params, thunkApi) => {
		try {
			const comments = (await axios.get(`${BACKEND_SCHEDULER_URL}${TASKS}/${params.taskId}/comments`)).data;
			return comments;
		} catch (err) {
			console.warn(err);
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const addTaskComment = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/addTaskComment',
	async (params, thunkApi) => {
		try {
			const comment = (await axios.post(`${BACKEND_SCHEDULER_URL}${TASKS}/${params.taskId}/comments`, params))
				.data;
			thunkApi.dispatch(getTaskComments({taskId: params.taskId}));
			return comment;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const updateTaskComment = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/updateTaskComment',
	async (params, thunkApi) => {
		try {
			const comment = (
				await axios.put(
					`${BACKEND_SCHEDULER_URL}${TASKS}/${params.taskId}/comments/${params.commentId}`,
					params
				)
			).data;
			thunkApi.dispatch(getTaskComments({taskId: params.taskId}));
			return comment;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const deleteTaskComment = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/deleteTaskComment',
	async (params, thunkApi) => {
		try {
			const comment = await axios.delete(
				`${BACKEND_SCHEDULER_URL}${TASKS}/${params.taskId}/comments/${params.commentId}`
			);
			thunkApi.dispatch(getTaskComments({taskId: params.taskId}));
			return comment;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const validateTaskFieldReport = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/validateTaskFieldReport',
	async (params, thunkApi) => {
		try {
			const response = (await axios.post(`${BACKEND_SCHEDULER_URL}${VALIDATE_TASKS}`, params)).data;
			return response;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const multiUpdateTask = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/multiUpdateTask',
	async (params, thunkApi) => {
		try {
			const task = (await axios.post(`${BACKEND_SCHEDULER_URL}${MULTI_UPDATE_TASK}`, params)).data;
			// thunkApi.dispatch(getAllTasks());
			thunkApi.dispatch(setReloadGrid());
			const taskCount = params?.ids?.length ?? 0;
			thunkApi.dispatch(showMessage({message: `${taskCount} Tasks Updated Successfully`, variant: 'success'}));
			return task;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const getTaskSummaryData = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/getTaskSummaryData',
	async (params, thunkApi) => {
		try {
			const {data} = await axios.get(`${BACKEND_SCHEDULER_URL}${TASK_SUMMARY_DATA}`);
			return data;
		} catch (err) {
			console.warn(err);
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const getTaskDailyCount = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/getTaskDailyCount',
	async (params, thunkApi) => {
		try {
			const taskMasterData = (await axios.get(`${BACKEND_SCHEDULER_URL}${GET_TASK_DAILY_COUNT}`, {params})).data;
			return taskMasterData;
		} catch (err) {
			console.warn(err);
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);